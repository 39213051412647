import React, { useEffect, useMemo, useState } from "react";
import { useLogin, useNotify } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios, { AxiosError } from "axios";
import { Slide, MenuItem, Select } from "@mui/material";
import config from "../config";

interface ErrorResponseData {
  message: string;
}
const countryCodes = [
  { value: "+91", label: "🇮🇳 (+91)" },
  { value: "+92", label: "🇵🇰 (+92)" },
  { value: "+880", label: "🇧🇩 (+880)" },
  { value: "+254", label: "🇰🇪 (+254)" },
  { value: "+998", label: "🇺🇿 (+998)" },
  { value: "+95", label: "🇲🇲 (+95)" },
  { value: "+216", label: "🇹🇳 (+216)" },
  { value: "+55", label: "🇧🇷 (+55)" },
  { value: "+52", label: "🇲🇽  (+52)" },
  { value: "+855", label: "🇰🇭 (+855)" },
  { value: "+977", label: "🇳🇵  (+977)" },
  { value: "+54", label: "🇦🇷  (+54)" },
  { value: "+591", label: "🇧🇴 (+591)" },
  { value: "+56", label: "🇨🇱  (+56)" },
  { value: "+57", label: "🇨🇴 (+57)" },
  { value: "+506", label: "🇨🇷  (+506)" },
  { value: "+1-809", label: "🇩🇴 (+1-809)" },
  { value: "+593", label: "🇪🇨  (+593)" },
  { value: "+20", label: "🇪🇬  (+20)" },
  { value: "+503", label: "🇸🇻  (+503)" },
  { value: "+233", label: "🇬🇭 (+233)" },
  { value: "+502", label: "🇬🇹 (+502)" },
  { value: "+234", label: "🇳🇬  (+234)" },
  { value: "+507", label: "🇵🇦  (+507)" },
  { value: "+595", label: "🇵🇾 (+595)" },
  { value: "+51", label: "🇵🇪  (+51)" },
  { value: "+27", label: "🇿🇦  (+27)" },
  { value: "+255", label: "🇹🇿 (+255)" },
  { value: "+256", label: "🇺🇬 (+256)" },
  { value: "+598", label: "🇺🇾  (+598)" },
  { value: "+260", label: "🇿🇲 (+260)" },
];
export default function LoginPage() {
  const [mobileNo, setMobileNo] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [inputOtp, setInputOtp] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [mobileNoIsValid, setMobileNoIsValid] = useState(false);

  const validateMobileNumber = (number: any) => {
    const isValid = /^\d{10}$/.test(number);
    setMobileNoIsValid(isValid);
  };

  const login = useLogin();
  const notify = useNotify();

  useEffect(() => {
    let intervalId: any;

    if (inputOtp) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [inputOtp, countdown]);

  const handleSendOTP = async () => {
    try {
      const fullPhoneNumber = `${countryCode}${mobileNo}`;
      const result = await axios.post(
        `/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          countryCode: countryCode.slice(1),
          mobileNo,
          build: {
            appVersion: "0.0.1",
            appCode: "Editor",
            isPlayStoreBuild: false,
            isGlobalBuild: false,
          },
        }
      );

      if (result.status === 201) {
        if (new Date(result.data.expiresAt) < new Date()) {
          notify("OTP is expired");
        } else {
          notify(`OTP is sent to ${fullPhoneNumber}`);
          setInputOtp(true);
          setCountdown(60);
        }
      }
    } catch (error) {
      notify("Error Sending OTP! Please try again later.");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!inputOtp) {
      axios
        .post(`/auth/init?key=${config.MAINTENANCE_BYPASS_KEY}`, {
          mobileNo,
          countryCode,
          build: {
            appVersion: "0.0.1",
            appCode: "Editor",
            isPlayStoreBuild: false,
            isGlobalBuild: false,
          },
        })
        .then((result) => {
          if (result.status === 201) {
            if (new Date(result.data.expiresAt) < new Date()) {
              notify("OTP is expired");
              validateMobileNumber(mobileNo);
            } else {
              notify(`OTP is sent to (${mobileNo})`);
              setInputOtp(true);
              setCountdown(60);
            }
          }
        })
        .catch(() =>
          notify("Error Sending OTP! Please try again after some time.")
        );
      handleSendOTP();
    } else {
      login({ mobileNo, otp, countryCode: countryCode.slice(1) }).catch(
        (err) => {
          if (err.response?.status === 401) notify("OTP is invalid");
          else notify(err.response?.data?.message || err.message);
        }
      );
    }
  };

  const mobileNumComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Input Phone Number
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            style={{ width: "500px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                sx={{
                  minWidth: "93px",
                  height: "40px",
                  fontSize: "0.675rem",
                  "& .MuiSelect-select": {
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  },
                }}
              >
                {countryCodes.map((country) => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                margin="normal"
                required
                value={mobileNo}
                inputProps={{ maxLength: 10 }}
                onChange={(e) => {
                  setMobileNo(e.target.value);
                  validateMobileNumber(e.target.value);
                }}
                fullWidth
                name="otp"
                label="Mobile Number"
                id="otp"
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!mobileNoIsValid}
            >
              Send OTP
            </Button>
          </Box>
        </Box>
      </Container>
    </Slide>
  );

  const otpComp = (
    <Slide direction="up" in>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verify OTP
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
            style={{ width: "500px" }}
          >
            <TextField
              margin="normal"
              required
              value={otp}
              inputProps={{ maxLength: 6 }}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              name="otp"
              label="OTP Code"
              id="otp"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button
              variant="text"
              onClick={() => setInputOtp(false)}
              sx={{ mt: 6, mb: 5 }}
              style={{
                marginTop: "-7px",
                marginLeft: "20rem",
              }}
            >
              Use another phone
            </Button>
          </Box>
        </Box>
      </Container>
    </Slide>
  );

  const signInComp = useMemo(
    () => (
      <>
        {inputOtp && (
          <Box
            sx={{
              position: "relative",
            }}
          >
            {otpComp}
            {countdown === 0 && (
              <Button
                onClick={handleSendOTP}
                style={{
                  position: "absolute",
                  top: "16rem",
                  left: "45.8%",
                  transform: "translate(-110%, -50%)",
                }}
              >
                Send OTP Again
              </Button>
            )}
            {countdown > 0 && (
              <Typography
                color="textSecondary"
                align="center"
                style={{
                  marginTop: "-70px",
                  marginLeft: "-19rem",
                }}
              >
                Resend OTP in {countdown} seconds
              </Typography>
            )}
          </Box>
        )}
        {!inputOtp && mobileNumComp}
      </>
    ),
    [inputOtp, otp, mobileNo, countryCode, countdown]
  );

  return <>{signInComp}</>;
}
