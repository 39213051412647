import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import StatsCard from "../components/StatsCard";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [revenueStats, setRevenueStats] = useState<any>({});

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const [gameType, setGameType] = useState("all");
  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#009933",
    "#008DDA",
    "#41C9E2",
    "#00ADB5",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const handleGameTypeSelect = (selectedGameType: string) => {
    setGameType(selectedGameType);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType,
      };

      setLoading(true);

      const revenueResponse = await axios.get(
        `${config.REST_ENDPOINT}/api/payment/revenue-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setRevenueStats(revenueResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, gameType]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Revenue Dashboard</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      {/* Revenue Stats */}
      <div style={{ marginTop: "20px" }}>
        <Typography
          variant="h6"
          style={{
            marginTop: -20,
            textAlign: "center",
            marginBottom: 20,
            backgroundColor: "#AA336A",
            color: "white",
          }}
        >
          Revenue Stats
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Sign Up Users</Typography>
              }
              value={revenueStats.totalSignUpUsers ?? 0}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[0 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total Sign Up Bonus Amount
                </Typography>
              }
              value={
                "₹" + (revenueStats.totalSignUpBonusAmount ?? 0).toFixed(2)
              }
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[1 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total Referral Users Count
                </Typography>
              }
              value={revenueStats.totalReferralUsersCount ?? 0}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[2 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">
                  Total Referral Bonus Amount
                </Typography>
              }
              value={
                "₹" + (revenueStats.totalReferralBonusAmount ?? 0).toFixed(2)
              }
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Game Commission</Typography>
              }
              value={"₹" + (revenueStats.totalGameCommission ?? 0).toFixed(2)}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[4 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Profit and Loss</Typography>
              }
              value={"₹" + (revenueStats.totalProfitAndLoss ?? 0).toFixed(2)}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total TDS Amount</Typography>
              }
              value={"₹" + (revenueStats.withdrawalTdsAmount ?? 0).toFixed(2)}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Converted Bonus Amount</Typography>
              }
              value={"₹" + (revenueStats.convertedBonusAmount ?? 0).toFixed(2)}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total GST Amount</Typography>
              }
              value={"₹" + (revenueStats.depositGstAmount ?? 0).toFixed(2)}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatsCard
              title={
                <Typography fontWeight="bold">Total Coupon Bonus Amount</Typography>
              }
              value={"₹" + (revenueStats.couponBonusAmount ?? 0).toFixed(2)}
              icon={
                <CurrencyRupeeIcon
                  style={{
                    fontSize: "20px",
                    color: colorPalette[3 % colorPalette.length],
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default GameplayDashboard;
